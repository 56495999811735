import './App.css';
import outside from './images/outside.jpg';

function Book() {
  return (
    <div className="book">
      <header>
        <h1>Boka</h1>
      </header>

      <section className="image">
        <img
          src={outside}
          alt="Outside view" // Changed from "Booking Image"
          className="responsive-image"
          width="100%"
          height="100%"
        />
      </section>

      <section className="allmäninfo">
        <h2>Allmän information</h2>
        <div className="container">
          <p>
            Bastun är uppställd drygt 20 minuters bilfärd från centrala Karlstad.
            <br />
            Den rymmer ca. 6 fullvuxna personer, då blir det inte för trångt.
            <br />
            På förstasidan finns bild inifrån bastun, där kan man få en uppfattning om hur stor den är.
          </p>
        </div>
      </section>

      <section className="priser">
        <h2>Priser</h2>
        <div className="container">
          <p>
            Ved för en sittning ingår i hyran. <br /><br />Behöver du mer ved?<br /><b>Inga problem – det går att köpa till!</b>
          </p>
          <ul className="myUL">
            <li>Dygnspris vardag (hämtas sön-tors) 600kr</li>
            <li>Dygnspris helg (hämtas fre-lör) 800kr</li>
            <li>Veckohyra 7 dagar 2000kr</li>
            <li>Månadshyra 31 dagar 6000kr</li>
            <li>Ved (ca 20 liter) 40kr</li>
            <li>Kontakta oss för längre bokningar!</li>
          </ul>
          <p>Vi kan också erbjuda utkörning och uppställning mot avgift.
            <br/>Startavgift 300:- och därefter tillkommer en kilometeravgift med 40:- per km från Bifrostvägen i Kil.
            <br/>Då ingår utkörning, uppställning/iordningställande, nedtagning och återlämnade.
            <br/>Vill man bara ha utkörning och fixar återlämnandet själv (eller tvärtom) kostar det 20:- per km.
          </p>
          <p> Alla priser är inklusive moms</p>
        </div>
      </section>

      <section className="obs">
        <h4>Läs igenom <a href="/Rules">reglerna</a> och ta reda på om den tilltänkta dragbilen<br />
        får dra släpet med den körkortsbehörighet föraren har!</h4>
      </section>

      <section className="kontakt">
        <h2><a href="/ContactUs">Kontakta oss</a> för lediga tider och bokningar</h2>
      </section>
    </div>
  );
};

export default Book;
